@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.Shortcut';

#{$name} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;

    + #{$name} {
        @include media-breakpoint-down(md) {
            border-top: 1px solid $sand-light;
        }

        @include media-breakpoint-up(md) {
            border-left: 1px solid $sand-light;
        }
    }

    @include media-breakpoint-up(sm) {
        transition: border-color linear .2s, background-color linear .2s;
        border-bottom: 2px solid $sand-light;

        &:hover {
            border-bottom: 2px solid $primary;
            background-color: $primary-light;
        }
    }

    @include media-breakpoint-up(md) {
        &:hover {
            justify-content: center;
        }
    }

    &:link, &:active, &:hover {
        text-decoration: none;

        #{$name}-title {
            color: $primary;
        }

        #{$name}-intro {
            color: $body-color;
        }
    }

    &-img {
        @include media-breakpoint-down(md) {
            height: 50px !important;
            width: auto;
        }
    }

    &-title {
        font-size: 1rem;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            margin-bottom: .5rem;
        }
    }

    &-intro {
        color: $body-color;
    }

    &-front {
        @include media-breakpoint-up(md) {
            #{$name}:hover & {
                opacity: 0;
            }
        }
    }

    &-back {
        opacity: 0;
        position: absolute;

        @include media-breakpoint-up(md) {
            #{$name}:hover & {
                opacity: 1;
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
